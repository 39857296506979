/** A non-mutable set with a size limit */
export class LimitedSet<T> implements Iterable<T> {
  public readonly sizeLimit: number;
  private readonly _set: Set<T>;

  constructor(sizeLimit: number, set?: Iterable<T>) {
    this._set = set ? new Set(set) : new Set();
    this.sizeLimit = sizeLimit;
    if (this.size > this.sizeLimit) {
      this._set = new Set([...this].slice(0, this.sizeLimit));
    }
  }

  [Symbol.iterator]() {
    return this._set.values();
  }

  has(value: T): boolean {
    return this._set.has(value);
  }

  get size() {
    return this._set.size;
  }

  get full() {
    return this.size >= this.sizeLimit;
  }

  concat(values: Iterable<T>): LimitedSet<T> {
    return new LimitedSet(this.sizeLimit, [...this, ...values]);
  }

  removeAll(values: Iterable<T>): LimitedSet<T> {
    const filterOut = [...values];
    return new LimitedSet(
      this.sizeLimit,
      [...this].filter((value) => !filterOut.includes(value))
    );
  }
}
