import { useCallback } from 'react';
import { LimitedSet } from '../domain/LimitedSet';

export const useMultiSelectHandlers = <T>(
  allKeys: T[],
  selectedKeys: LimitedSet<T>,
  setSelectedKeys: (keys: LimitedSet<T>) => void
): [
  handleSelectKey: (selected: boolean, key: T) => void,
  handleSelectAllKeys: (selected: boolean) => void
] => {
  const handleSelectKey = useCallback(
    (selected: boolean, key: T) => {
      if (selected) {
        setSelectedKeys(selectedKeys.concat([key]));
      } else {
        setSelectedKeys(selectedKeys.removeAll([key]));
      }
    },
    [selectedKeys, setSelectedKeys]
  );

  const handleSelectAllKeys = useCallback(
    (selected: boolean) => {
      if (selected) {
        setSelectedKeys(selectedKeys.concat(allKeys));
      } else {
        setSelectedKeys(selectedKeys.removeAll(selectedKeys));
      }
    },
    [allKeys, selectedKeys, setSelectedKeys]
  );

  return [handleSelectKey, handleSelectAllKeys];
};
