/* eslint-disable*/
import { CancelTokenSource } from 'axios';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCancelTokenSource } from '../../api/client';
import { getStoreConfigs, getStoreDetailByNumber } from '../../api/store';
import { StoreConfigsResponse, StoreDetail } from '../../types/types';
import { getSelectedStore } from '../../utils/utils';

export interface StoreState {
  store?: StoreDetail;
  loading: boolean;
  hasApiError: boolean;
  isAddressDoctorEnabled: boolean;
  isRetentionEnabled: boolean;
  referenceContactInterval: number;
  contactWeekLimit: number;
  isExchangeEnabled: boolean;
  exchangePeriod: string;
  isGoogleAddressValidationEnabled: any;
  isExchangeFuncEnabled: any;
  rewardsEligibilityAmount: number;
  customerRewards: boolean
  customerVerificationOptimization: boolean
  isLMDEnabled: boolean;
  maxReceiptsEmailable: number;
}

const NO_LIMIT = 9999;
const NO_LIMIT_STRING = '-1';
const NO_INTERVAL_LIMIT = 0;

export interface StoreDispatchState {
  fetchStoreDetailsByNumber: (
    storeNumber: string,
    cancelToken: CancelTokenSource
  ) => Promise<void | StoreDetail[]>;
}

export const checkIfAddressDoctorEnabled = (
  response: StoreConfigsResponse[]
): boolean => {
  const addressDoctor = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'AddressDoctorEnabledIndicator' &&
      config.paramCategoryName === 'AddressDoctorEnabled' &&
      config.paramKeyName === 'AddressDoctorEnabled'
    );
  });
  if (addressDoctor && addressDoctor.paramValue === '1') {
    return true;
  }
  return false;
};

export const getLMDEnabled = (
  response: StoreConfigsResponse[]
): boolean => {
  const lmd = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramKeyName === 'EnableLMD'
    );
  });
  if (lmd && lmd.paramValue === '1') {
    return true;
  }
  return false;
};

export const checkIfRetentionEnabled = (
  response: StoreConfigsResponse[]
): boolean => {
  const retentionResponse = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'PaymentAccess' &&
      config.paramCategoryName === 'SubSystemAccess' &&
      config.paramKeyName === 'CustomerRetention'
    );
  });
  if (retentionResponse && retentionResponse.paramValue === '1') {
    return true;
  }
  return false;
};

export const getAgreementOpenPeriod = (
  response: StoreConfigsResponse[]
): string => {
  const exchangeResponse = response?.[0]?.configDetails?.find(
    (config) => config.paramKeyName === 'AgreementExchangePeriod'
  );
  if (exchangeResponse && exchangeResponse.paramValue) {
    return exchangeResponse.paramValue;
  }
  return '';
};
export const checkIfExchangeEnabled = (
  response: StoreConfigsResponse[]
): boolean => {
  const exchangeResponse = response?.[0]?.configDetails?.find(
    (config) => config.paramKeyName === 'AgreementExchange'
  );
  if (exchangeResponse && exchangeResponse.paramValue === '1') {
    return true;
  }
  return false;
};
export const getContactWeekLimit = (
  response: StoreConfigsResponse[]
): number => {
  const configResp = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'CommunicationLimit' &&
      config.paramCategoryName === 'AccountManagement' &&
      config.paramKeyName === 'CustomerContactLimitPastWeek'
    );
  });
  if (configResp && configResp.paramValue !== NO_LIMIT_STRING) {
    return Number(configResp.paramValue);
  }
  return NO_LIMIT;
};

export const checkIsAddressValidationEnabled = (response: StoreConfigsResponse[]) => {
  const exchangeResponse = response?.[0]?.configDetails?.find(
    (config) => config.paramKeyName === 'EnableGoogleAddressValidation'
  );
  if (exchangeResponse && exchangeResponse.paramValue === '1') {
    return true;
  }
  return false;
}
export const getReferenceContactInterval = (
  response: StoreConfigsResponse[]
): number => {
  const configResp = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramGroupName === 'CommunicationLimit' &&
      config.paramCategoryName === 'AccountManagement' &&
      config.paramKeyName === 'ReferenceContactInterval'
    );
  });
  if (configResp && configResp.paramValue !== NO_LIMIT_STRING) {
    return Number(configResp.paramValue);
  }
  return NO_INTERVAL_LIMIT;
};

export const checkIsExchangeFuncEnabled = (response: StoreConfigsResponse[]) => {
  const exchangeResponse = response?.[0]?.configDetails?.find(
    (config) => config.paramKeyName === 'RACExchange'
  );
  if (exchangeResponse && exchangeResponse.paramValue === '1') {
    return true;
  }
  return false;
}

export const CustomerRewards = (
  response: StoreConfigsResponse[]
): boolean => {
  const exchangeResponse = response?.[0]?.configDetails?.find(
    (config) => config.paramKeyName === 'CustomerRewards'
  );
  if (exchangeResponse && exchangeResponse.paramValue === '1') {
    return true;
  }
  return false;
};
export const CustomerVerificationOptimization = (
  response: StoreConfigsResponse[]
): boolean => {
  const exchangeResponse = response?.[0]?.configDetails?.find(
    (config) => config.paramKeyName === 'CustomerVerificationOptimization'
  );
  if (exchangeResponse && exchangeResponse.paramValue === '1') {
    return true;
  }
  return false;
};
export const RewardsEligibilityAmount = (
  response: StoreConfigsResponse[]
): number => {
  const configResp = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramKeyName === 'RewardsEligibilityAmount'
    );
  });
  if (configResp && configResp.paramValue !== NO_LIMIT_STRING) {
    return Number(configResp.paramValue);
  }
  return NO_INTERVAL_LIMIT;
};

export const getMaxReceiptsEmailable = (
  response: StoreConfigsResponse[]
): number => {
  const maxReceipts = response?.[0]?.configDetails?.find((config) => {
    return (
      config.paramKeyName === 'MaxReceiptsEmailable'
    );
  });
  // if maxReceipts is undefined, NaN || 0 will still be 0
  return Number(maxReceipts?.paramValue) || 0;
};

export const StoreStateContext = createContext<StoreState>({} as StoreState);
export const StoreDispatchContext = createContext<StoreDispatchState>(
  {} as StoreDispatchState
);

export const StoreProvider = (props: { children: ReactNode }) => {
  const [store, setStore] = useState<StoreDetail>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasApiError, setHasApiError] = useState(false);
  const [isAddressDoctorEnabled, setIsAddressDoctorEnabled] = useState(false);
  const [isRetentionEnabled, setIsRetentionEnabled] = useState(false);
  const [isExchangeEnabled, setIsExchangeEnabled] = useState(false);
  const [exchangePeriod, setExchangePeriod] = useState<string>('');
  const [contactWeekLimit, setContactWeekLimit] = useState(NO_LIMIT);
  const [isGoogleAddressValidationEnabled, setIsGoogleAddressValidationEnabled] = useState<boolean>(false);
  const [isExchangeFuncEnabled, setIsExchangeFuncEnabled] = useState<boolean>(false);
  const [referenceContactInterval, setReferenceContactInterval] =
    useState(NO_LIMIT);
  const [rewardsEligibilityAmount, setRewardsEligibilityAmount] = useState(NO_LIMIT)
  const [customerRewards, setCustomerRewards] = useState<boolean>(false);
  const [customerVerificationOptimization, setCustomerVerificationOptimization]
    = useState<boolean>(false)
  const [isLMDEnabled, setIsLMDEnabled] = useState(false);
  const [maxReceiptsEmailable, setMaxReceiptsEmailable] = useState(0);
  const fetchStoreConfig = (
    selectedStore: string,
    cancelToken: CancelTokenSource
  ) => {
    const paramKeyName = [
      'AddressDoctorEnabled',
      'CustomerRetention',
      'CustomerContactLimitPastWeek',
      'ReferenceContactInterval',
      'AgreementExchange',
      'AgreementExchangePeriod',
      'EnableGoogleAddressValidation',
      'RACExchange',
      'RewardsEligibilityAmount',
      'CustomerRewards',
      'Txt2PayCardOnFile',
      'CustomerVerificationOptimization',
      'EnableLMD',
      'MaxReceiptsEmailable',
    ];
    getStoreConfigs(
      {
        storeNumbers: [selectedStore],
        paramKeyNames: paramKeyName,
      },
      cancelToken.token
    )
      .then((response) => {
        setIsRetentionEnabled(checkIfRetentionEnabled(response));
        setIsAddressDoctorEnabled(checkIfAddressDoctorEnabled(response));
        setContactWeekLimit(getContactWeekLimit(response));
        setReferenceContactInterval(getReferenceContactInterval(response));
        setIsExchangeEnabled(checkIfExchangeEnabled(response));
        setExchangePeriod(getAgreementOpenPeriod(response));
        setIsGoogleAddressValidationEnabled(checkIsAddressValidationEnabled(response));
        setIsExchangeFuncEnabled(checkIsExchangeFuncEnabled(response));
        setRewardsEligibilityAmount(RewardsEligibilityAmount(response));
        setCustomerRewards(CustomerRewards(response));
        setCustomerVerificationOptimization(CustomerVerificationOptimization(response))
        setIsLMDEnabled(getLMDEnabled(response));
        setMaxReceiptsEmailable(getMaxReceiptsEmailable(response));
      })
      .catch(() => {
        //noop
      });
  };

  useEffect(() => {
    const cancelToken: CancelTokenSource = getCancelTokenSource();
    const selectedStore = getSelectedStore();
    fetchStoreDetailsByNumber(selectedStore, cancelToken);
    fetchStoreConfig(selectedStore, cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, []);

  const fetchStoreDetailsByNumber = async (
    storeNumber: string,
    cancelToken: CancelTokenSource
  ) => {
    setHasApiError(false);
    setLoading(true);
    getStoreDetailByNumber(storeNumber, cancelToken.token)
      .then(
        (response) =>
          response?.response?.length && setStore(response.response[0])
      )
      .catch(() => setHasApiError(true))
      .finally(() => setLoading(false));
  };

  return (
    <StoreStateContext.Provider
      value={{
        store,
        loading,
        hasApiError,
        isAddressDoctorEnabled,
        isRetentionEnabled,
        referenceContactInterval,
        contactWeekLimit,
        isExchangeEnabled,
        exchangePeriod,
        isGoogleAddressValidationEnabled,
        isExchangeFuncEnabled,
        rewardsEligibilityAmount,
        customerRewards,
        customerVerificationOptimization,
        isLMDEnabled,
        maxReceiptsEmailable
      }}
    >
      <StoreDispatchContext.Provider
        value={{
          fetchStoreDetailsByNumber,
        }}
      >
        {props.children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  );
};

export const useStoreDetails = () => useContext(StoreStateContext);

export const useStoreActions = () => useContext(StoreDispatchContext);
