import { makeStyles, RACCard, RACTabs } from '@rentacenter/racstrap';
import React, { useCallback } from 'react';
import { AgreementInfoProvider } from '../../../context/AgreementInfo/AgreementInfoProvider';
import { AgreementInfo } from './AgreementInfo';
import { WorkedHistory } from './WorkedHistory';
import { CurrentPaymentHistory } from './CurrentPaymentHistory';
import { PaymentHistoryProvider } from '../../../context/PaymentHistory/PaymentHistoryProvider';
import { LimitedSet } from '../../../domain/LimitedSet';
import { HistoryTab } from '../../../types/types';

export const historyDatatestId = 'historyDatatestId';

const useClasses = makeStyles(() => ({
  historyCard: {
    boxSizing: 'border-box',
    borderRadius: '1rem',
    marginTop: '1rem',
    marginBottom: '9rem',
  },
  cardBody: {
    padding: '.5rem',
    width: '100%',
    boxSizing: 'border-box',
  },
}));

const AVAILABLE_TABS = [
  'Worked History',
  'Payment History',
  'Agreement Information',
];

interface HistoryProps {
  selectedReceipts: LimitedSet<string | number | undefined>;
  setSelectedReceipts: (
    receipts: LimitedSet<string | number | undefined>
  ) => void;
  disableEmailReceipts: boolean;
  setSelectedTab: (tab: HistoryTab) => void;
}

const useSelectTabHandler = (
  setSelectedTab: (tab: HistoryTab) => void
): ((event: unknown, n: number) => void) =>
  useCallback(
    (_: unknown, n: number) => {
      if (n === 0) {
        setSelectedTab(HistoryTab.Worked);
      } else if (n === 1) {
        setSelectedTab(HistoryTab.Payment);
      } else if (n === 2) {
        setSelectedTab(HistoryTab.Agreement);
      }
    },
    [setSelectedTab]
  );

export const History = ({
  selectedReceipts,
  setSelectedReceipts,
  disableEmailReceipts,
  setSelectedTab,
}: HistoryProps) => {
  const classes = useClasses();
  const handleSelectTab = useSelectTabHandler(setSelectedTab);

  return (
    <RACCard className={classes.historyCard} data-testid={historyDatatestId}>
      <div className={classes.cardBody}>
        <RACTabs
          loadAllTabContentOnMount
          defaultValue={0}
          tabs={AVAILABLE_TABS}
          onChange={handleSelectTab}
          contentForTabs={[
            <WorkedHistory key="worked-history" />,
            <PaymentHistoryProvider key="current-payment-history">
              <CurrentPaymentHistory
                selectedReceipts={selectedReceipts}
                setSelectedReceipts={setSelectedReceipts}
                disableEmailReceipts={disableEmailReceipts}
              />
            </PaymentHistoryProvider>,
            <AgreementInfoProvider key="agreement-info">
              <AgreementInfo />
            </AgreementInfoProvider>,
          ]}
        />
      </div>
    </RACCard>
  );
};
