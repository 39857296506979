/* eslint-disable prettier/prettier */
import { Grid, makeStyles } from '@rentacenter/racstrap';
import React, { useState, useCallback, useEffect } from 'react';
import { emailReceipts } from '../../../api/payments';
import { ApiStateWrapper } from '../../../common/ApiStateWrapper/ApiStateWrapper';
import { Footer } from '../../../common/Footer/Footer';
import { CustomerAlertsProvider } from '../../../context/CustomerAlert/CustomerAlertsProvider';
import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
import { CustomerHeaderProvider } from '../../../context/CustomerHeader/CustomerHeaderProvider';
import { CustomerPaymentSummaryProvider } from '../../../context/CustomerPaymentSummary/CustomerPaymentSummaryProvider';
import { useUserPermissions } from '../../../context/permission/PermissionsProvider';
import { PrintLetterProvider } from '../../../context/PrintLetter/PrintLetterProvider';
import { useStoreDetails } from '../../../context/Store/StoreProvider';
import { TextConversationProvider } from '../../../context/TextConversationProvider/TextConversationProvider';
import { WorkedHistoryProvider } from '../../../context/WorkedHistory/WorkedHistoryProvider';
import { LimitedSet } from '../../../domain/LimitedSet';
import { HistoryTab } from '../../../types/types';
import { ContactInformation } from '../ContactInformation/ContactInformation';
import { CustomerHeader } from '../CustomerInformation/CustomerHeader';
import { CustomerInformation } from '../CustomerInformation/CustomerInformation';
import { CustomerInformationFooter } from '../CustomerInformation/CustomerInformationFooter';
import { History } from '../History/History';
import { TextConversation } from '../TextConversation/TextConversation';
import { Toast } from './Toast';

export const useStyles = makeStyles(() => ({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
}));

const useEmailReceiptsHandler = (
  selectedReceipts: LimitedSet<string | number | undefined>,
  setSelectedReceipts: (set: LimitedSet<string | number | undefined>) => void,
  setIsEmailingReceipts: (disabled: boolean) => void,
  customerId: string | undefined,
  onEmailReceiptsSuccess: (numberOfReceiptsEmailed: number) => void
): (() => Promise<void>) =>
  useCallback(async () => {
    if (!customerId) {
      return;
    }
    setIsEmailingReceipts(true);
    try {
      const response = await emailReceipts(customerId, [...selectedReceipts].map(String));
      if (response.message !== 'Email sent successfully') {
        throw new Error('Failed to email receipts.');
      }
      onEmailReceiptsSuccess(selectedReceipts.size);
      setSelectedReceipts(new LimitedSet(10));
    } finally {
      setIsEmailingReceipts(false);
    }
  }, [
    selectedReceipts,
    setSelectedReceipts,
    setIsEmailingReceipts,
    customerId,
    onEmailReceiptsSuccess,
  ]);

export const CustomerDetails = () => {
  const ownClasses = useStyles();
  const {
    loading,
    hasApiError,
    customerDetails: { customerId, emailAddress },
  } = useCustomerDetails();
  const { hasAccessToTextConversation } = useUserPermissions();
  const customerDetailLoaded =
    loading !== undefined && !loading && !hasApiError;
  const [selectedReceipts, setSelectedReceipts] = useState<
    LimitedSet<string | number | undefined>
  >(new LimitedSet(10));
  const [isEmailingReceipts, setIsEmailingReceipts] = useState(false);
  const [showEmailReceiptSuccess, setShowEmailReceiptSuccess] = useState(false);
  const [showEmailMultipleReceiptsSuccess, setShowEmailMultipleReceiptsSuccess] = useState(false);
  const { maxReceiptsEmailable } = useStoreDetails();
  const [selectedTab, setSelectedTab] = useState<HistoryTab>(HistoryTab.Worked);

  useEffect(() => setSelectedReceipts(new LimitedSet(maxReceiptsEmailable)), [maxReceiptsEmailable]);

  const handleEmailReceiptsSuccess = useCallback((numberOfReceiptsEmailed: number) => {
    if (numberOfReceiptsEmailed === 1) {
      setShowEmailReceiptSuccess(true);
    } else {
      setShowEmailMultipleReceiptsSuccess(true);
    }
  }, []);

  const handleEmailReceipts = useEmailReceiptsHandler(
    selectedReceipts,
    setSelectedReceipts,
    setIsEmailingReceipts,
    customerId,
    handleEmailReceiptsSuccess
  );

  const handleCloseEmailReceiptsSuccess = useCallback(() => {
    setShowEmailReceiptSuccess(false);
    setShowEmailMultipleReceiptsSuccess(false);
  }, []);

  return (
    <>
      <ApiStateWrapper
        loading={loading}
        hasApiError={hasApiError}
        successContent={<></>}
        classes={{
          loader: ownClasses.contentHeight,
          apiError: ownClasses.contentHeight,
        }}
      />
      {customerDetailLoaded && (
        <WorkedHistoryProvider>
          <CustomerHeaderProvider>
            <CustomerPaymentSummaryProvider>
              <CustomerAlertsProvider>
                <CustomerHeader />
              </CustomerAlertsProvider>
              <CustomerInformation />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <TextConversationProvider>
                    <ContactInformation />
                  </TextConversationProvider>
                </Grid>
                {hasAccessToTextConversation && (
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <TextConversationProvider>
                      <TextConversation />
                    </TextConversationProvider>
                  </Grid>
                )}
              </Grid>

              <History
                selectedReceipts={selectedReceipts}
                setSelectedReceipts={setSelectedReceipts}
                disableEmailReceipts={isEmailingReceipts}
                setSelectedTab={setSelectedTab}
              />
              <Footer>
                <PrintLetterProvider>
                  <CustomerInformationFooter
                    disableEmailReceiptsButton={
                      selectedReceipts.size === 0 || isEmailingReceipts
                    }
                    selectedReceiptCount={selectedReceipts.size}
                    isEmailingReceipts={isEmailingReceipts}
                    handleEmailReceipts={handleEmailReceipts}
                    selectedTab={selectedTab}
                  />
                </PrintLetterProvider>
              </Footer>
            </CustomerPaymentSummaryProvider>
          </CustomerHeaderProvider>
        </WorkedHistoryProvider>
      )}
      {(hasApiError || loading) && (
        <Footer>
          <PrintLetterProvider>
            <CustomerInformationFooter
              disableEmailReceiptsButton={
                selectedReceipts.size === 0 || isEmailingReceipts
              }
              selectedReceiptCount={selectedReceipts.size}
              isEmailingReceipts={isEmailingReceipts}
              handleEmailReceipts={handleEmailReceipts}
              selectedTab={selectedTab}
            />
          </PrintLetterProvider>
        </Footer>
      )}
    <Toast
      open={showEmailReceiptSuccess}
      onClose={handleCloseEmailReceiptsSuccess}
      message={`Receipt successfully emailed to ${emailAddress}`}
    />
    <Toast
      open={showEmailMultipleReceiptsSuccess}
      onClose={handleCloseEmailReceiptsSuccess}
      message={`Receipts successfully emailed to ${emailAddress}`}
    />
    </>
  );
};
