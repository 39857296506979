import React, { memo } from 'react';
import {
  Portal,
  RACAlert,
  Snackbar,
  SnackbarOrigin,
} from '@rentacenter/racstrap';
import { AlertProps } from '@mui/material';

interface ToastProps {
  open: boolean;
  onClose: () => void;
  message: string;
  location?: SnackbarOrigin;
  severity?: AlertProps['severity'];
  variant?: AlertProps['variant'];
}

const ToastComponent = ({
  open,
  onClose,
  message,
  location = { vertical: 'bottom', horizontal: 'left' },
  severity = 'success',
  variant = 'filled',
}: ToastProps) => (
  <Portal>
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={location}
    >
      <RACAlert
        handleClose={onClose}
        severity={severity}
        variant={variant}
        AlertMessage={message}
      />
    </Snackbar>
  </Portal>
);

export const Toast = memo(ToastComponent);
